<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('收入记录')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="inc-panel">
            <div class="inc-tabs">
                <van-tabs v-model="activeIndex" @click="onChange" sticky offset-top="60px" swipe-threshold="4">
                    <van-tab :title="$t('做单')"></van-tab>
                    <van-tab :title="$t('本金返还')"></van-tab>
                    <van-tab :title="$t('出售')"></van-tab>
                </van-tabs>
            </div>
            <div class="page-main">
                <van-pull-refresh
                        :loosing-text="$t('释放即可刷新')"
                        :pulling-text="$t('下拉即可刷新')"
                        :loading-text="$t('加载中')"
                        v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            :loading-text="$t('加载中')"
                            :finished-text="$t('没有更多了')"
                            @load="onLoad"
                    >
                        <div v-for="(item, index) in list" :key="index" class="inc-card">
                            <div class="flex_bd">
                                <div class="inc-info">{{$t(item.title)}}</div>
                                <div class="wt-items">
                                    <div class="label text-gray">{{$t('金额')}}：</div>
                                    <div class="value text-green" v-if="item.money > 0">
                                        {{ item.money >= 0 ? '+':'' }}{{parseFloat(item.money).toFixed(6)}}{{ $t('USDT') }}
                                    </div>
                                    <div class="value text-red" v-else>
                                        {{ item.money >= 0 ? '+':'' }}{{parseFloat(item.money).toFixed(6)}}{{ $t('USDT') }}
                                    </div>
                                </div>
                                <div class="wt-items">
                                    <div class="label">{{$t('时间')}}： </div>
                                    <div class="time">{{item.created_at}}</div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                activeIndex: 0,
                at_list: [0, 5, 6],
                active: 0,
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onChange(index) {
                this.active = this.at_list[index];
                this.list = [];
                this.page = 0;
                this.onLoad();
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                let url = this.$api_url.income_list + '?page=' + this.page + '&tag=list';
                console.log('a', this.active);
                // if (this.active != '') {
                    url = url + '&type=' + this.active;
                // }
                this.$axios
                    .get(url)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        }

    }
</script>
<style>
    .inc-sidebar .van-sidebar-item__text {
        width: 100%;
    }
</style>